// import api from "@/configs/api";

import StatisticsService from '@/services/StatisticsService'

const state = {
  all: {}
}

const actions = {
  all ({ commit }) {
    return StatisticsService.all().then(response => {
      if (response.data.success) {
        commit('all', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  all (state, data) {
    state.all = data
  }
}

const getters = {
  all (state) {
    return state.all
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
