import { deepCopy } from '@/helpers/common'

const state = {
  galleries: {}
}

const actions = {

}

const mutations = {
  galleries (state, data) {
    if (data && Array.isArray(data.data)) {
      const d = data.data.filter(e => e)
      state.galleries[data.name] = d
    }
    state.galleries = deepCopy(state.galleries)
  },
  remove (state, data) {
    state.galleries[data.name].splice(data.index, 1)
    state.galleries = deepCopy(state.galleries)
  },
  add (state, data) {
    state.galleries[data.name].push(data.data)
    state.galleries = deepCopy(state.galleries)
  }
}

const getters = {
  galleries (state) {
    return state.galleries
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
