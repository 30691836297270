// import api from "@/configs/api";

import ReportsService from '@/services/ReportsService'

const state = {
  datatablesConfiguration: {
    ajax: (data, callback, settings) => {
      actions.inDatatables(data).then((result) => {
        state.inDatatables = result.data.data
        callback(result.data.data)
      })
    },
    order: [[0, 'desc']],
    processing: true,
    serverSide: true,
    columnDefs: [
      {
        targets: 0,
        orderable: true
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: true
      }, {
        targets: 3,
        orderable: true
      }, {
        targets: 4,
        orderable: true
      }, {
        targets: 5,
        orderable: true
      }
    ],
    searchDelay: 500
  },
  current: {
    people: [{}]
  }
}

const actions = {
  inDatatables (query) {
    return ReportsService.datatables(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  remove ({ commit }, report) {
    return ReportsService.remove(report.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  archive ({ commit }, report) {
    return ReportsService.archive(report.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  inDatatables (state, data) {
    state.inDatatables = data
  },
  current (state, data) {
    state.current = data
  }
}

const getters = {
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  inDatatables (state) {
    return state.inDatatables
  },
  current (state) {
    return state.current
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
