import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/prices',
  component: MainLayout,
  children: [{
    path: '/',
    name: 'prices',
    props: {
      'pricelist-type': 'pricelist'
    },
    component: () => import('@/modules/prices/index')
  }, {
    path: 'funeral-house-prices',
    name: 'funeral-house-prices',
    props: {
      'pricelist-type': 'funeral-house'
    },
    component: () => import('@/modules/prices/index')
  }]
}]
