<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo">
        <logos-main-logo></logos-main-logo>
      </span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">{{ $t('global.app') }}</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-speedometer" to="/dashboard">{{ $t('menu.dashboard') }}</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-card" to="/transactions"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('transactions'))">
        {{ $t('menu.transactions') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-clipboard" to="/dead-book"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('dead-book')) || $store.getters['users/isFuneralCompany']">
        {{ $t('menu.deadBook') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-clipboard" to="/animal-book"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('animal-book'))">
        {{ $t('menu.animalBook') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-bookmarks" to="/tombstones"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('tombstones'))">
        {{ $t('menu.tombstones') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-browsers" to="/tombstone-additions"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('tombstone-additions'))">
        {{ $t('menu.tombstoneAdditions') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-contact" to="/users"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('users'))">
        {{ $t('menu.users') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-contact" to="/partners"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('partners'))">
        {{ $t('menu.partners') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-contact" to="/admins"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('admins'))">
        {{ $t('menu.admins') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-paper" to="/ads"
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('ads'))">
        {{ $t('menu.ads') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-cash" to="/prices" exact
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('prices'))">
        {{ $t('menu.pricelist') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-cash" to="/prices/funeral-house-prices" exact
                           v-if="$store.getters['users/isSuperAdmin'] || ($store.getters['users/isAdmin'] && hasPermission('prices/funeral-house-prices'))">
        {{ $t('menu.pricelistFuneralHouses') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-warning" to="/reports" exact
                           v-if="$store.getters['users/isSuperAdmin']">
        {{ $t('menu.reports') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-document" to="/sites" exact
                           v-if="$store.getters['users/isSuperAdmin']">
        {{ $t('menu.sites') }}
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-log-out" to="#" @click="logout" :exact="true">
        {{ $t('menu.logout') }}
      </sidenav-router-link>
      <div class="sidenav-item language-change">
        <div class="sidenav-link">
          <b-select :options="$t('languages')" v-model="selectedLanguage"></b-select>
        </div>
      </div>
      <!--
      <sidenav-router-link icon="ion ion-md-clipboard" to="/invoices">Faktury i zestawienia</sidenav-router-link>
      -->
    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import LogosMainLogo from '../components/logos/MainLogo'

export default {
  name: 'app-layout-sidenav',
  components: {
    LogosMainLogo,
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      selectedLanguage: window.appLanguage
    }
  },
  created () {
    this.selectedLanguage = window.appLanguage
  },
  watch: {
    selectedLanguage: function () {
      // window.app.$i18n.locale = this.selectedLanguage
      localStorage.setItem('selectedAppLanguage', this.selectedLanguage)
      // window.appLanguage = this.selectedLanguage
      window.location.reload()
    }
  },
  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    },
    permissions () {
      return this.$store.getters['users/permissions']
    },
    user () {
      return this.$store.getters['users/user']
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('users/logout').then(e => {
        this.$router.push({ name: 'login' })
        this.$snotify.success(this.$t('logout.success'))
      })
    },
    hasPermission (name) {
      const permission = this.permissions.find(e => e.name === name)
      if (permission) {
        return this.user.permissions.indexOf(permission.id) > -1
      }
      return false
    },
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },
    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>

<style lang="scss">
  .language-change {
    position: fixed;
    bottom: 0;
  }
  .layout-collapsed {
    .language-change {
      display: none;
    }
  }
</style>
