// import api from "@/configs/api";

import TombstonesService from '@/services/TombstonesService'

const state = {
  datatablesConfiguration: {
    rowClick: (datatables, data) => {
    },
    ajax: (data, callback, settings) => {
      data.place = state.filterCementary
      data.religion_name = state.filterReligion
      data.type_amount = state.filterTypeAmount
      actions.inDatatables(data).then((result) => {
        state.inDatatables = result.data.data
        callback(result.data.data)
      })
    },
    order: [[0, 'asc']],
    processing: true,
    serverSide: true,
    dom: 'Bfrtip',
    buttons: [],
    columnDefs: [
      {
        targets: 0,
        orderable: false
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: true,
        width: '140px'
      }
    ],
    searchDelay: 500
  },
  current: {},
  all: [],
  imageTypes: [],
  ordering: [],
  filterCementary: null,
  filterReligion: null,
  filterTypeAmount: null
}

const actions = {
  inDatatables (query) {
    return TombstonesService.datatables(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  ordering ({ commit }, params) {
    return TombstonesService.ordering(params.place, params.type_amount, params.religion_name).then(result => {
      if (result.data.success) {
        commit('ordering', result.data.data)
      }
      return result
    }).catch((e) => {
      return false
    })
  },
  saveOrdering ({ commit }) {
    const ids = state.ordering.map(e => e.id)
    return TombstonesService.saveOrdering({ ids }).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  all ({ commit }, params = null) {
    return TombstonesService.all(params).then(response => {
      if (response.data.success) {
        commit('all', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  save ({ commit }) {
    return TombstonesService.save(state.current).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  remove ({ commit }, tombstone) {
    return TombstonesService.remove(tombstone.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  imageTypes ({ commit }) {
    return TombstonesService.imageTypes().then(response => {
      if (response.data.success) {
        commit('imageTypes', response.data.data)
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  inDatatables (state, data) {
    state.inDatatables = data
  },
  current (state, data) {
    state.current = data
  },
  all (state, data) {
    state.all = data
  },
  imageTypes (state, data) {
    state.imageTypes = data
  },
  filterCementary (state, data) {
    state.filterCementary = data
  },
  filterReligion (state, data) {
    state.filterReligion = data
  },
  filterTypeAmount (state, data) {
    state.filterTypeAmount = data
  },
  ordering (state, data) {
    state.ordering = data
  }
}

const getters = {
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  inDatatables (state) {
    return state.inDatatables
  },
  current (state) {
    return state.current
  },
  all (state) {
    return state.all
  },
  imageTypes (state) {
    return state.imageTypes
  },
  filterCementary (state) {
    return state.filterCementary
  },
  filterReligion (state) {
    return state.filterReligion
  },
  filterTypeAmount (state) {
    return state.filterTypeAmount
  },
  ordering (state) {
    return state.ordering
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
