import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/admins',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/admins/index')
  }]
}]
