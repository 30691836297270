import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/animal-book',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/animal-book/index')
  }]
}]
