import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/partners',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/partners/index')
  }, {
    path: ':id',
    name: 'partner-details',
    component: () => import('@/modules/users/details')
  }]
}]
