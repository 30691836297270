import HTTP from './HTTP'

export default {
  all (pricelistType) {
    return HTTP.get(`panel/prices/${pricelistType}`)
  },
  save (data, pricelistType) {
    return HTTP.post(`panel/prices/${pricelistType}`, data)
  }
}
