import HTTP from './HTTP'

export default {
  all () {
    return HTTP.get('panel/options')
  },
  save (options) {
    return HTTP.post(`panel/options`, options)
  }
}
