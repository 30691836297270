import MainLayout from '@/layouts/Layout2'

export default [
  {
    path: '/users',
    component: MainLayout,
    children: [
      {
        path: '/',
        component: () => import('@/modules/users/index')
      }, {
        path: ':id',
        name: 'user-details',
        component: () => import('@/modules/users/details')
      }
    ]
  }
]
