const state = {
  submitIndicator: false
}

const actions = {
  setSubmitIndicator ({ commit }, flag) {
    commit('setSubmitIndicator', flag)
  }
}

const mutations = {
  setSubmitIndicator (state, flag) {
    state.submitIndicator = flag
  }
}

const getters = {
  submitIndicator: (state) => state.submitIndicator
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
