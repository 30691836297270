import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/dashboard',
  component: MainLayout,
  children: [{
    path: '/',
    name: 'dashboard',
    component: () => import('@/modules/dashboard/index')
  }]
}]
