import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/ads/datatables', {
      params: query
    })
  },
  save (data) {
    if (data.id) {
      return HTTP.put('panel/ads/' + data.id, data)
    }
    return HTTP.post(`panel/ads`, data)
  },
  all () {
    return HTTP.get(`panel/ads`)
  },
  remove (id) {
    return HTTP.delete(`panel/ads/${id}`)
  }
}
