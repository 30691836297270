const state = {
  errors: []
}

const actions = {
  setErrors ({ commit }, errors) {
    commit('setErrors', errors)
  }
}

const mutations = {
  setErrors (state, errors) {
    state.errors = errors
    if (!errors.success) {
      const title = this.$app.$t('validator.title')
      let content = `<div style="color: #fff; margin: 10px 0 0 0;"><h5>${title}</h5>`
      let list = []
      Object.keys(errors.data).forEach((key, index) => {
        if (typeof errors.data[key] === 'string') {
          list.push('<li>' + this.$app.$t(key + '.' + errors.data[key]) + '</li>')
        } else {
          errors.data[key].forEach(e => {
            list.push('<li>' + key + ': ' + this.$app.$t(e) + '</li>')
          })
        }
      })
      content += '<ul>' + list.join('') + '</ul></div>'
      this.$app.$snotify.html(content, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        type: 'error'
      })
    }
  }
}

const getters = {
  errors: (state) => state.errors
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
