import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/tombstone-additions',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/tombstone-additions/index')
  }]
}]
