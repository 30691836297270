import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/sites',
  component: MainLayout,
  children: [
    {
      path: '/',
      name: 'sites.index',
      component: () => import('@/modules/sites/index')
    },
    {
      path: ':identifier',
      name: 'sites.show',
      component: () => import('@/modules/sites/show')
    }
  ]
}]
