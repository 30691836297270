import UserService from '@/services/UserService'
import PermissionsService from '@/services/PermissionsService'

const state = {
  user: {},
  token: '',
  datatablesConfiguration: {
    rowClick: (datatables, data, clickEvent) => {
      const route = data.role === 'funeral-company' ? 'partner-details' : 'user-details'

      window.app.$router.push({ name: route, params: { id: data.id } })
    },
    ajax: (data, callback, settings) => {
      // data.module_id = state.selectedModule
      actions.inDatatables(data).then((result) => {
        state.inDatatables = result.data.data
        callback(result.data.data)
      })
    },
    order: [[0, 'desc']],
    processing: true,
    serverSide: true,
    dom: 'Bfrtip',
    buttons: [
      'colvis'
    ],
    columnDefs: [
      {
        targets: 0,
        orderable: true
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: false,
        width: '80px'
      }
    ],
    searchDelay: 500
  },
  current: {},
  credentials: {
    email: '',
    password: '',
    password_confirmation: '',
    rememberMe: true
  },
  genders: [
    { value: 'm', text: 'male' },
    { value: 'f', text: 'female' }
  ],
  places: [
    { value: 'main', text: '' },
    { value: 'catacombs', text: '' },
    { value: 'animal', text: '' }
  ],
  userType: 'admin',
  permissions: [],
  funeralCompanies: []
}

const actions = {
  login ({ commit }) {
    return UserService.login(state.credentials).then(response => {
      if (response.data.success) {
        commit('storeUser', response.data.data)
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  logout ({ commit }, data) {
    return UserService.logout().then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return e
    })
  },
  inDatatables (query) {
    return UserService.datatables(query, state.userType).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  // eslint-disable-next-line no-empty-pattern
  search ({ }, query) {
    return UserService.search(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  funeralCompanies ({ commit }) {
    return UserService.funeralCompanies(true).then(result => {
      if (result.data.success) {
        commit('funeralCompanies', result.data.data)
      }
      return result
    }).catch((e) => {
      return false
    })
  },
  save ({ commit }) {
    return UserService.save(state.current).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    })
  },
  remove ({ commit }, user) {
    return UserService.remove(user.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  sendNewPassword ({ commit }, user) {
    return UserService.sendNewPassword(user.id).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  sendConfirmationEmail ({ commit }, user) {
    return UserService.sendConfirmationEmail(user.id).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  remindPassword ({ commit }) {
    return UserService.remindPassword(state.credentials.email).then(response => {
      return true
    }).catch((e) => {
      return false
    })
  },
  suspend ({ commit }, data) {
    return UserService.suspend(data.user.id, data.flag).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  permissions ({ commit }) {
    return PermissionsService.all().then(response => {
      commit('permissions', response.data.data)
      return response
    }).catch((e) => {
      return false
    })
  },
  getByToken ({ commit }, token) {
    return UserService.getByToken(token).then(response => {
      if (response.data.success) {
        commit('getByToken', response.data.data)
        return true
      }
      return response
    }).catch((e) => {
      return false
    })
  },
  changePassword ({ commit }, token) {
    const data = {
      user: {
        token,
        email: state.credentials.email,
        password: state.credentials.password,
        password_confirmation: state.credentials.password_confirmation
      }
    }
    return UserService.changePassword(data).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  userDetails ({ commit }, id) {
    return UserService.userDetails(id).then(response => {
      commit('current', response.data.data)
      return response
    }).catch((e) => {
      return false
    })
  },
  userAffiliations ({ commit }, { id, queryData }) {
    return UserService.userAffiliations(id, queryData).then(response => {
      commit('extendCurrent', response.data.data)
      return response
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  getByToken (state, data) {
    if (data.email) {
      state.credentials.email = data.email
    }
  },
  storeUser (state, userData) {
    if (userData) {
      state.user = userData.user
      if (typeof userData.access_token !== 'undefined') {
        state.token = userData.access_token
      }
      if (typeof userData.permissions !== 'undefined') {
        state.permissions = userData.permissions
      }
      localStorage.setItem('userAllData', JSON.stringify(userData))
      localStorage.setItem('user', JSON.stringify(userData.user))
      localStorage.setItem('token', state.token)
    }
  },
  clearUser (state) {
    state.user = null
    state.token = null
    localStorage.removeItem('userAllData')
    localStorage.removeItem('user')
    localStorage.removeItem('token')
  },
  inDatatables (state, data) {
    state.inDatatables = data
  },
  credentials (state, data) {
    state.credentials = data
  },
  current (state, data) {
    state.current = data
  },
  extendCurrent (state, data) {
    state.current = Object.assign({}, state.current || {}, data)
  },
  userType (state, data) {
    state.userType = data
  },
  permissions (state, data) {
    state.permissions = data
  },
  funeralCompanies (state, data) {
    state.funeralCompanies = data
  }
}

function hasRole (user, role) {
  let out = false
  user.roles.forEach(e => {
    if (e.name === role) {
      out = true
    }
  })
  return out
}

const getters = {
  user (state) {
    return state.user
  },
  token (state) {
    return state.token
  },
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  inDatatables (state) {
    return state.inDatatables
  },
  credentials (state) {
    return state.credentials
  },
  isSuperAdmin (state) {
    if (!state.user) return false
    return hasRole(state.user, 'super-admin')
  },
  isAdmin (state) {
    if (!state.user) return false
    return hasRole(state.user, 'admin')
  },
  isFuneralCompany (state) {
    if (!state.user) return false
    return hasRole(state.user, 'funeral-company')
  },
  current (state) {
    return state.current
  },
  genders (state) {
    return state.genders
  },
  places (state) {
    return state.places
  },
  userType (state) {
    return state.userType
  },
  permissions (state) {
    return state.permissions
  },
  funeralCompanies (state) {
    return state.funeralCompanies
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
