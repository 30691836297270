import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/dead-book',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/dead-book/index')
  }]
}]
