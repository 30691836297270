const language = {
  global: {
    app: 'Wirtualny Cmentarz',
    'not-implemented': 'Do implementacji...',
    saved: 'Zapisano dane',
    removed: 'Rekord usunięty',
    archived: 'Rekord zarchiwizowany',
    activated: 'Rekord aktywowany',
    amount: 'Ilość',
    ok: 'OK',
    cancel: 'Anuluj',
    save: 'Zapisz',
    yes: 'Tak',
    no: 'Nie',
    clear: 'Czyść',
    remove: 'Usuń',
    close: 'Zamknij',
    generate: 'Generuj',
    addImage: 'Dodaj obraz',
    comment: 'Komentarz',
    saveAndBack: 'Zapisz i wróć',
    chooseColumns: 'Wybierz kolumny',
    indefinitely: 'Bezterminowo',
    atLeastThree: 'Wpisz przynajmniej 3 znaki',
    search: 'Szukaj',
    buriedPeople: 'Pochowani w kwaterze',
    ownerAll: 'Miej na uwadze, że zmieniasz właściciela wszystkich osób pochowanych w danej kwaterze.',
    required: 'Wymagane.'
  },
  languages: [
    {
      value: 'pl',
      text: 'Polski'
    }, {
      value: 'en',
      text: 'English'
    }, {
      value: 'sk',
      text: 'Slovenský'
    }
  ],
  forms: {
    login: {
      emailPhone: 'Email / Nr telefonu',
      forgotPassword: 'Zapomniałeś hasła?',
      password: 'Hasło',
      rememberMe: 'Zapamiętaj mnie',
      login: 'Login',
      loginScreen: 'Logowanie',
      remindPassword: 'Przypomnij hasło',
      metaTitle: 'Logowanie'
    }
  },
  menu: {
    dashboard: 'Kokpit',
    transactions: 'Transakcje',
    deadBook: 'Księga zmarłych',
    animalBook: 'Księga zwierząt',
    tombstones: 'Nagrobki',
    tombstoneAdditions: 'Dodatki nagrobków',
    users: 'Użytkownicy',
    admins: 'Administratorzy',
    partners: 'Partnerzy',
    ads: 'Reklamy',
    pricelist: 'Cennik',
    pricelistFuneralHouses: 'Cennik partnerzy',
    reports: 'Zgłoszenia',
    sites: 'Strony',
    logout: 'Wyloguj'
  },
  paidOptions: [
    {
      value: null,
      text: 'Wszystkie'
    },
    {
      value: 'paid',
      text: 'Tylko płatne'
    },
    {
      value: 'free',
      text: 'Tylko bezpłatne'
    }
  ],
  contents: {
    dashboard: {
      title: 'Statystyki',
      mainStatistics: 'Pochówki na cmentarzu głównym',
      catacombsStatistics: 'Pochówki w katakumbach',
      animalStatistics: 'Pochówki na cmentarzu zwierząt',
      registeredUsers: 'Zarejestrowani użytkownicy',
      religion: 'Religia',
      mainCemetery: 'Cmentarz',
      catacombs: 'Katakumby',
      last24h: 'Ostatnie 24h',
      last7days: 'Ostatnie 7 dni',
      lastMonth: 'Ostatni miesiąc',
      lastYear: 'Ostatni rok',
      registeredInTime: 'Zarejestrowani w czasie',
      loggedInTime: 'Zalogowani w czasie',
      funeralsPaid: 'Pochówki płatne',
      funeralsFree: 'Pochówki darmowe',
      additionsPaid: 'Dodatki płatne',
      additionsFree: 'Dodatki darmowe'
    },
    transactions: {
      title: 'Transakcje',
      transactionId: 'ID transakcji',
      paidUntil: 'Opłacono do',
      user: 'Użytkownik',
      orderType: 'Rodzaj zakupu',
      gateway: 'Bramka płatności',
      price: 'Kwota',
      created: 'Utworzono',
      export_invoices: 'Eksport faktur',
      total: 'Suma całkowita',
      paid_filter: 'Typ transakcji',
      room: 'Kwatera',
      finished: 'zakończona',
      unpaid: 'nieopłacona',
      removeModal: {
        title: 'Usuwanie transakcji',
        elements: 'Które elementy chcesz usunąć?',
        all: 'Transakcję i powiązane (grób i osoby / pamiątki)',
        transaction: 'Tylko transakcję'
      },
      invoices: {
        date_from: 'Data od',
        date_to: 'Data do',
        funeral_company: 'Partner',
        pick_one: 'Wybierz aby filtrować',
        get_invoices_link: 'Pobierz faktury w PDF',
        get_edipp_link: 'Eksport faktur do formatu Edi++',
        get_edipp_link_disabled: 'Musisz wybrać "data od" i "data do" aby wyeksportować faktury do formatu EdiPP',
        amount_of_invoices: 'Ilość faktur w zaznaczonym przedziale',
        file: 'faktury',
        single: 'faktura',
        none: 'brak'
      },
      actions: {
        title: 'Akcje',
        invoice: 'Faktura',
        edit: 'Edytuj',
        remove: 'Usuń'
      },
      export_transactions: 'Zestawienie księgowe',
      export: {
        with_invoices: 'Z fakturami',
        without_invoices: 'Bez faktur',
        refunds: 'Zwroty',
        get_transactions_link: 'Pobierz zestawienie',
        file: 'eksport'
      }
    },
    deadBook: {
      title: 'Księga zmarłych',
      add: 'Dodaj pochówek',
      filter: 'Filtrowanie',
      created: 'Data dodania',
      name: 'Imię',
      surname: 'Nazwisko',
      nick: 'Pseudonim',
      sex: 'Płeć',
      birthday: 'Urodziny',
      death: 'Śmierć',
      place: 'Miejsce',
      link: 'Link',
      actions: {
        title: 'Akcje',
        edit: 'Edytuj',
        move: 'Zmień właściciela',
        remove: 'Usuń'
      },
      showModal: {
        close: 'Zamknij',
        edit: 'Edycja pochówku',
        add: 'Dodawanie pochówku',
        name: 'Imię',
        surname: 'Nazwisko',
        nick: 'Pseudonim',
        sex: 'Płeć',
        birthday: 'Data urodzin',
        death: 'Data zgonu',
        memorial: 'Podpis',
        memorial_text: 'Treść',
        ownerName: 'Imię właściciela',
        ownerSurname: 'Nazwisko właściciela',
        ownerEmail: 'Email właściciela',
        ownerPhone: 'Telefon właściciela',
        emailContent: 'Treść wiadomości email',
        smsContent: 'Treść wiadomości SMS',
        variables: 'Zmienne',
        varName: 'imię zmarłego',
        varSurname: 'nazwisko zmarłego',
        varLink: 'link, pod jakim będzie znajdował się pochówek',
        portrait: 'Portrait',
        album: 'Album',
        autoSend: 'Wysyła automatycznie, gdy wypełnione'
      },
      moveModal: {
        title: 'Zmiana właściciela',
        sourceUser: 'Właściciel teraz',
        targetUser: 'Przyszły właściciel'
      }
    },
    animalBook: {
      title: 'Księga zwierząt'
    },
    tombstones: {
      title: 'Nagrobki',
      allCemeteries: 'Wszystkie cmentarze',
      allReligions: 'Wszytkie religie',
      allTombstoneTypes: 'Wszystkie typy grobów',
      ordering: 'Sortowanie nagrobków',
      add: 'Dodaj nagrobek',
      id: 'ID',
      image: 'Obraz',
      place: 'Miejsce',
      religion: 'Religia',
      species: 'Gatunek',
      type: 'Typ',
      used: 'Użyto razy',
      actions: {
        title: 'Akcje',
        edit: 'Edytuj',
        remove: 'Usuń'
      },
      newTombstone: 'Nowy nagrobek',
      editTombstone: 'Edycja nagrobku',
      chooseAllFilters: 'Wybierz wszystkie filtry, aby zobczyć kolejność na stronie. Przesunięcie zdjęcia autmatycznie zapisuje kolejność.'
    },
    tombstoneAdditions: {
      title: 'Dodatki do grobów',
      image: 'Obraz',
      type: 'Typ',
      all: 'Wszystkie typy',
      add: 'Dodaj dodatek',
      addTypes: 'Dodaj typy',
      used: 'Użyto razy',
      actions: {
        title: 'Akcje',
        edit: 'Edytuj',
        remove: 'Usuń'
      },
      newTombstoneAddition: 'Nowy dodatek',
      editTombstoneAddition: 'Edycja dodatku'
    },
    users: {
      representative: 'Przedstawiciel',
      address: 'Adres',
      url: 'WWW',
      tax_id: 'NIP',
      title: 'Użytkownicy',
      user: 'Użytkownik',
      addUser: 'Dodaj użytkownika',
      id: 'ID',
      surname: 'Nazwisko',
      surnameName: 'Nazwisko / Nazwa firmy',
      companyName: 'Nazwa firmy',
      discount: 'Rabat - Pochówki (0-100%)',
      souvenirs_discount: 'Rabat - Pamiątki (0-100%)',
      nick: 'Pseudonim',
      name: 'Imię',
      email: 'Email',
      phone: 'Telefon',
      emailConfirmed: 'Email potwierdzony w dniu',
      activeTombstones: 'Aktywne pochówki',
      activeTombstonesShort: 'AK.P.',
      archiveTombstones: 'Archiwalne pochówki',
      archiveTombstonesShort: 'AR.P.',
      additions: 'Dodatki',
      registration: 'Rejestracja',
      lastLogin: 'Ostatnie logowanie',
      emailVerified: 'Konto zweryfikowane',
      emailVerifiedShort: 'KZ',
      agreedNotifications: 'Zgoda na powiadomienia',
      agreedNotificationsShort: 'ZP',
      agreedEmails: 'Zgoda na emaile',
      agreedEmailsShort: 'ZE',
      role: 'Rola',
      newPassword: 'Nowe hasło',
      newPasswordAgain: 'Powtórz nowe hasło',
      newPasswordWillBeSent: 'Nowe hasło zostanie wysłane emailem.',
      lastLogged: 'Ostatnio zalogowany',
      tombstoneValid: 'Ważność pochówku',
      validTo: 'Ważny do',
      menuAccess: 'Dostęp do menu',
      confirmDeleteTitle: 'Potwierdzenie usunięcia',
      confirmDelete: 'Czy jesteś pewien, że chcesz usunąć tego użytkownika? Ten użytkonik ma: <strong>{0}</strong> aktywnych pochówków, <strong>{1}</strong> archiwalnych pochówków i <strong>{2}</strong> dodatków.',
      monthlyInvoices: 'Faktury miesięczne',
      editUser: 'Edycja użytkownika',
      randomPassword: 'Jeśli nie wpiszesz hasła nowemu użytkownikowi, emailem zostanie mu wysłane hasło losowe.',
      actions: {
        title: 'Akcje',
        edit: 'Edytuj',
        impersonate: 'Zaloguj jako',
        remove: 'Usuń',
        suspend: 'Zawieś konto',
        active: 'Aktywuj konto',
        newPassword: 'Wyślij nowe hasło',
        confirmRegistration: 'Wyślij potwierdzenie na email'
      },
      details: {
        tombstones: {
          name: 'Pochówki',
          noSingleTombstones: 'Brak pochówków jednoosobowych',
          noMultiTombstones: 'Brak pochówków wieloosobowych',
          noAnimalTombstones: 'Brak pochówków zwierząt',
          nameSurname: 'Imię i nazwisko',
          nick: 'Pseudonim',
          death: 'Data śmierci',
          tombstoneId: 'Numer kwatery',
          addedDate: 'Data dodania (IP)',
          validUntil: 'Zapłacono do',
          link: 'Link',
          inArchives: 'W archiwum',
          linkName: 'Link do cmentarza',
          extend: 'Przedłóż',
          activate: 'Aktywuj',
          archive: 'Archiwizuj',
          remove: 'Usuń'
        },
        transactions: {
          name: 'Transakcje',
          date: 'Data (IP)',
          type: 'Typ',
          price: 'Cena',
          concerns: 'Dotyczy',
          noTransactions: 'Brak transakcji'
        },
        affiliation: {
          name: 'Afiliacja',
          urlPl: 'Link afiliacyjny [PL]',
          urlEn: 'Link afiliacyjny [EN]',
          generateAffiliation: 'Wygeneruj token afiliacyjny dla użytkownika',
          month: 'Miesiąc',
          monthlyIncome: 'Suma przychodu za wybrany miesiąc'
        },
        invoices: {
          name: 'Faktury',
          id: 'Numer faktury',
          date: 'Data',
          type: 'Typ',
          price: 'Cena',
          get_invoice: 'Pobierz',
          noInvoices: 'Brak faktur'
        }
      }
    },
    admins: {
      name: 'Administratorzy'
    },
    partners: {
      name: 'Partnerzy',
      notificationHint: 'Na podany adres email będą wysyłane powiadomienia o nowych partnerach. Zostaw puste, aby wyłączyć.'
    },
    ads: {
      title: 'Reklamy',
      addAd: 'Dodaj reklamę',
      name: 'Nazwa',
      link: 'Link',
      zone: 'Strefa',
      showingFrom: 'Wyświetlana od',
      showingTo: 'Wyświetlana do',
      time: 'Rotacja',
      actions: {
        title: 'Akcje',
        edit: 'Edytuj',
        remove: 'Usuń'
      },
      newAd: 'Nowa reklama',
      editAd: 'Edycja reklamy',
      rotation: 'Czas rotacji [s]',
      image: 'Obraz'
    },
    pricelist: {
      activeShort: 'A',
      active: 'Aktywny?',
      title: 'Nazwa',
      validDays: 'Ważny dni (0 - na zawsze)',
      addPosition: 'Dodaj pozycję',
      remove: 'Usuń'
    },
    reports: {
      created: 'Data zgłoszenia',
      report: 'Zgłoszenie',
      condolence: 'Kondolencja',
      funeral: 'Pochówek',
      lodging_no: 'Nr kwatery',
      actions: {
        archive: 'Archiwizuj',
        remove: 'Usuń'
      }
    },
    sites: {
      title: 'Strony',
      name: 'Nazwa'
    }
  },
  users: {
    'new-password-sent': 'Nowe hasło zostało wysłane.',
    'confirmation-email-sent': 'Email został wysłany.',
    'account-unsuspended': 'Konto odwieszone.',
    'account-suspended': 'Konto zawieszone.',
    'account-suspended-title': 'Konto zawieszone',
    'account-suspended-l': '(Z)',
    'password-changed': 'Hasło zostało zmienione. Możesz się już zalogować.'
  },
  places: {
    main: 'Cmentarz',
    catacombs: 'Katakumby',
    animal: 'C. zwierząt'
  },
  transaction: {
    type: {
      tombstone: 'Pochówek',
      addition: 'Dodatek'
    }
  },
  genders: {
    m: 'Mężczyzna',
    f: 'Kobieta',
    'null': '-'
  },
  gendersAnimals: {
    m: 'm',
    f: 'ż',
    'null': '-'
  },
  roles: {
    'super-admin': 'Super Admin',
    'admin': 'Admin',
    'funeral-company': 'Partner',
    'user': 'Użytkownik'
  },
  permissions: {
    'transactions': 'Transakcje',
    'dead-book': 'Księga zmarłych',
    'animal-book': 'Księga zwierząt',
    'tombstones': 'Nagrobki',
    'tombstone-additions': 'Dodatki nagrobków',
    'users': 'Użytkownicy',
    'admins': 'Administratorzy',
    'partners': 'Partnerzy',
    'ads': 'Reklamy',
    'pages': 'Strony',
    'prices': 'Cennik ogólny',
    'prices/funeral-house-prices': 'Cennik partnerzy'
  },
  zones: {
    zone1: 'Tablica 2 pozycja',
    zone2: 'Tablica 4 pozycja',
    zone3: 'Duch na cmentarzu',
    zone4: 'Strefa 4',
    zone5: 'Strefa 5',
    zone6: 'Strefa 6'
  },
  tombstoneAdditions: {
    light: 'Świeczka',
    flower: 'Kwiat',
    stone: 'Kamień',
    other: 'Inne'
  },
  periods: {
    '1-day': '1 dzień',
    '30-days': '30 dni',
    '1-year': '1 rok',
    'forever': 'na zawsze'
  },
  religions: {
    catholicism: {
      name: 'Katolicyzm'
    },
    buddhism: {
      name: 'Buddyzm '
    },
    bible_students_jehov: {
      name: 'Badacze Pisma Świętego i Jehowi'
    },
    orthodoxy: {
      name: 'Prawosławie'
    },
    hinduism: {
      name: 'Hinduizm'
    },
    islam: {
      name: 'Islam'
    },
    judaism: {
      name: 'Judaizm'
    },
    shinto: {
      name: 'Shinto'
    },
    atheism: {
      name: 'Ateizm'
    },
    romas: {
      name: 'Romowie'
    },
    polish_tatars: {
      name: 'Polscy Tatarzy'
    },
    others: {
      name: 'Inne'
    },
    celebrities: {
      name: 'Celebryci'
    },
    null: {
      name: 'Cmentarz komunalny'
    },
    none: 'Cmentarz komunalny'
  },
  species: {
    reptiles: {
      name: 'Gady'
    },
    rodents: {
      name: 'Gryzonie'
    },
    horses: {
      name: 'Konie'
    },
    cats: {
      name: 'Koty'
    },
    monkeys: {
      name: 'Małpy'
    },
    insects: {
      name: 'Owady'
    },
    amphibians: {
      name: 'Płazy'
    },
    dogs: {
      name: 'Psy'
    },
    birds: {
      name: 'Ptaki'
    },
    fishes: {
      name: 'Ryby'
    },
    mammals_others: {
      name: 'Ssaki - inne'
    },
    others: {
      name: 'Inne'
    },
    none: 'Brak'
  },
  typeAmount: {
    single: {
      name: 'Pojedyncze'
    },
    double: {
      name: 'Podwójne'
    },
    multi: {
      name: 'Wieloosobowe'
    }
  },
  login: {
    'incorrect-email-password': 'Nieprawidłowy login lub hasło',
    success: 'Zalogowano pomyślnie'
  },
  logout: {
    success: 'Wylogowano pomyślnie'
  },
  prices: {
    title: 'Cennik'
  },
  graves: {
    single: 'Grób jednoosobowy',
    multi: 'Grób wieloosobowy',
    additions: 'Dodatki'
  },
  transactions: {
    'App\\Models\\Funerals\\Funeral': {
      'info': {
        title: 'Kwatera'
      },
      'model-created': 'Nowa kwatera',
      'model-extended': 'Przedłużenie kwatery'
    },
    'App\\Models\\Funerals\\Person': {
      'info': {
        title: 'Pochówek'
      },
      'model-created': 'Nowy pochówek',
      'model-extended': 'Przedłużenie pochówku'
    },
    'App\\Models\\Memory': {
      'info': {
        title: 'Dodatek'
      },
      'model-created': 'Nowy dodatek',
      'model-extended': 'Przedłużenie dodatku'
    }
  },
  validator: {
    title: 'Nieprawidłowe dane'
  },
  validation: {
    email: 'Nieprawidłowy email',
    confirm: 'Pola muszą być takie same',
    unique: 'Pole musi być unikalne'
  }
}

export default language
