import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/reports',
  component: MainLayout,
  children: [
    {
      path: '/',
      name: 'reports.index',
      component: () => import('@/modules/reports/index')
    }
  ]
}]
