import axios from 'axios'
import store from '../store/store'
import jquery from 'jquery'
import api from '../configs/api'

const HTTP = axios.create({
  baseURL: api.my.url,
  paramsSerializer: (params) => {
    return jquery.param(params)
  }
})

let isBlob = false

HTTP.blob = () => {
  isBlob = true
  return HTTP
}

HTTP.interceptors.request.use(
  config => {
    const token = store.getters['users/token']
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (window.appLanguage !== 'pl') {
      config.headers.Language = window.appLanguage
    }
    if (isBlob) {
      config.responseType = 'blob'
    }
    return config
  }
)

HTTP.interceptors.response.use((response) => {
  store.dispatch('indicators/setSubmitIndicator', false)
  if (response.headers.authorization) {
    let token = response.headers.authorization.replace('Bearer ', '')
    console.log('new token arrived: ' + token)
    store.commit('user/storeToken', token)
  }
  isBlob = false
  return response
}, (error) => {
  if (typeof error.response.status !== 'undefined') {
    switch (error.response.status) {
      case 422: // validator error
        store.dispatch('errors/setErrors', error.response.data)
        break
    }
  }
  store.dispatch('indicators/setSubmitIndicator', false)
  isBlob = false
  return Promise.reject(error)
})

export default HTTP
