import HTTP from './HTTP'

export default {
  save (data) {
    return HTTP.post('panel/sites/' + data.id, data)
  },
  one (id) {
    return HTTP.get('panel/sites/' + id)
  },
  all () {
    return HTTP.get(`panel/sites`)
  }
}
