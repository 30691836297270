import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('religions/datatables', {
      params: query
    })
  },
  save (data) {
    if (data.id) {
      return HTTP.put('religions/' + data.id, data)
    }
    return HTTP.post(`religions`, data)
  },
  all () {
    return HTTP.get(`religions`)
  },
  remove (id) {
    return HTTP.delete(`religions/${id}`)
  }
}
