// import api from "@/configs/api";

import OptionsService from '@/services/OptionsService'

const state = {
  current: {},
  all: []
}

const actions = {
  all ({ commit }) {
    return OptionsService.all().then(response => {
      if (response.data.success) {
        commit('all', response.data.data)
        commit('current', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  // one ({ commit }, params) {
  //   return OptionsService.one(params.identifier).then(response => {
  //     if (response.data.success) {
  //       commit('current', response.data.data)
  //     }
  //     return false
  //   }).catch((e) => {
  //     return false
  //   })
  // },
  save ({ commit }) {
    return OptionsService.save(state.current).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  current (state, data) {
    state.current = data
  },
  all (state, data) {
    state.all = data
  }
}

const getters = {
  current (state) {
    return state.current
  },
  all (state) {
    return state.all
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
