import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/tombstones/datatables', {
      params: query
    })
  },
  save (data) {
    if (data.id) {
      return HTTP.put('panel/tombstones/' + data.id, data)
    }
    return HTTP.post(`panel/tombstones`, data)
  },
  saveOrdering (data) {
    return HTTP.put(`panel/tombstones/ordering`, data)
  },
  all (params) {
    return HTTP.get(`panel/tombstones`, { params })
  },
  remove (id) {
    return HTTP.delete(`panel/tombstones/${id}`)
  },
  imageTypes () {
    return HTTP.get('panel/tombstones/image-types')
  },
  ordering (place, typeAmount, religionName) {
    return HTTP.get('panel/tombstones/ordering', {
      params: { place, type_amount: typeAmount, religion_name: religionName }
    })
  }
}
