// import api from "@/configs/api";

import AdsService from '@/services/AdsService'

const state = {
  datatablesConfiguration: {
    rowClick: (datatables, data) => {
    },
    ajax: (data, callback, settings) => {
      // data.module_id = state.selectedModule
      actions.inDatatables(data).then((result) => {
        state.inDatatables = result.data.data
        callback(result.data.data)
      })
    },
    // order: [[0, 'asc']],
    processing: true,
    serverSide: true,
    dom: 'Bfrtip',
    buttons: [
      'colvis'
    ],
    columnDefs: [
      {
        targets: 0,
        orderable: true
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: false,
        width: '80px'
      }
    ],
    searchDelay: 500
  },
  current: {},
  all: []
}

const actions = {
  inDatatables (query) {
    return AdsService.datatables(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  all ({ commit }) {
    return AdsService.all().then(response => {
      if (response.data.success) {
        commit('all', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  save ({ commit }) {
    return AdsService.save(state.current).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  remove ({ commit }, ad) {
    return AdsService.remove(ad.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  inDatatables (state, data) {
    state.inDatatables = data
  },
  current (state, data) {
    state.current = data
  },
  all (state, data) {
    state.all = data
  }
}

const getters = {
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  inDatatables (state) {
    return state.inDatatables
  },
  current (state) {
    return state.current
  },
  all (state) {
    return state.all
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
