import VueI18n from 'vue-i18n'
import Snotify from 'vue-snotify'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store/store'
import 'datatables.net-dt/css/jquery.dataTables.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/vendor/libs/vue-multiselect/vue-multiselect.scss'

import BootstrapVue from 'bootstrap-vue'
import globals from './globals'
import Popper from 'popper.js'
import VuexRouterSync from 'vuex-router-sync'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import pl from 'vee-validate/dist/locale/pl.json'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

localize({ en, pl })

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

VuexRouterSync.sync(store, router)

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Snotify)
Vue.use(VueI18n)

let language = 'en'
const polishPanel = [
  'panel.wirtualnycmentarz.pl',
  'panel.cmentarz.usermd.net',
  'www.panel.wirtualnycmentarz.pl',
  'www.panel.cmentarz.usermd.net'
]
const skPanel = [
  'panel.nezabudneme.sk',
  'www.panel.nezabudneme.sk'
]
polishPanel.push('localhost')
if (polishPanel.indexOf(window.location.hostname) > -1) {
  language = 'pl'
}
if (skPanel.indexOf(window.location.hostname) > -1) {
  language = 'sk'
}
localize(language)

window.appLanguage = language
let selectedAppLanguage = localStorage.getItem('selectedAppLanguage')
if (selectedAppLanguage) {
  window.appLanguage = selectedAppLanguage
}

const i18n = new VueI18n({
  locale: window.appLanguage,
  fallbackLocale: window.appLanguage,
  messages: {
    pl: require('./locales/pl').default,
    en: require('./locales/en').default,
    sk: require('./locales/sk').default
  }
})

extend('confirm', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: (_, values) => i18n.t('validation.confirm', values)
})

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)

// Global RTL flag
Vue.mixin({
  data: globals
})

let allUserData = localStorage.getItem('userAllData')
if (allUserData) {
  allUserData = JSON.parse(allUserData)
  store.commit('users/storeUser', allUserData)
}

// require('./helpers/veevalidate-locales.js')

window.appName = i18n.t('global.app')
window.loginTitle = i18n.t('forms.login.metaTitle')
window.app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

store.$app = window.app
window.store = store
