// import api from "@/configs/api";

import TransactionsService from '@/services/TransactionsService'

const state = {
  datatablesConfiguration: {
    rowClick: (datatables, data) => {
    },
    ajax: (data, callback, settings) => {
      data.date_from = state.dateFrom
      data.date_to = state.dateTo
      if (state.funeralCompany) {
        data.user_id = state.funeralCompany.id
      }
      data.paid_filter = state.paidFilter
      actions.inDatatables(data).then((result) => {
        window.store.commit('transactions/inDatatables', result.data.data)
        window.store.commit('transactions/transactionsCopy', result.data.data.data)
        callback(result.data.data)
      })
    },
    order: [[0, 'desc']],
    processing: true,
    serverSide: true,
    dom: 'Bfrtip',
    buttons: [],
    searchDelay: 500
  },
  current: {
    place: 'main',
    people: [{}]
  },
  dateFrom: null,
  dateTo: null,
  funeralCompany: null,
  invoicesAmount: 0,
  transactionsCopy: [],
  transactionsAmount: {
    with_invoices: 0,
    without_invoices: 0,
    refunds: 0
  },
  paidFilter: 'paid'
}

const actions = {
  inDatatables (query) {
    return TransactionsService.datatables(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  remove ({ commit }, person) {
    return TransactionsService.remove(person.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  removeAll ({ commit }, person) {
    return TransactionsService.removeAll(person.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  invoicesAmount ({ commit }, dates) {
    return TransactionsService.invoicesAmount(dates.date_from, dates.date_to).then(response => {
      if (response.data.success) {
        commit('invoicesAmount', response.data.data)
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  getInvoices ({ commit }, dates) {
    return TransactionsService.getInvoices(dates.date_from, dates.date_to).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  getEdiPP ({ commit }, dates) {
    return TransactionsService.getEdiPP(dates.date_from, dates.date_to).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  invoice ({ commit }, id) {
    return TransactionsService.invoice(id).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  },
  transactionsAmount ({ commit }, dates) {
    return TransactionsService.transactionsAmount(dates.date_from, dates.date_to).then(response => {
      if (response.data.success) {
        commit('transactionsAmount', response.data.data)
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  getTransactions ({ commit }, dates) {
    return TransactionsService.getTransactions(dates.date_from, dates.date_to).then(response => {
      return response
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  inDatatables (state, data) {
    state.inDatatables = data
  },
  transactionsCopy (state, data) {
    state.transactionsCopy = data
  },
  current (state, data) {
    state.current = data
  },
  invoicesAmount (state, data) {
    state.invoicesAmount = data
  },
  transactionsAmount (state, data) {
    state.transactionsAmount = data
  },
  dateFrom (state, data) {
    state.dateFrom = data
  },
  dateTo (state, data) {
    state.dateTo = data
  },
  funeralCompany (state, data) {
    state.funeralCompany = data
  },
  paidFilter (state, data) {
    state.paidFilter = data
  }
}

const getters = {
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  transactionsCopy (state) {
    return state.transactionsCopy
  },
  inDatatables (state) {
    return state.inDatatables
  },
  current (state) {
    return state.current
  },
  invoicesAmount (state) {
    return state.invoicesAmount
  },
  transactionsAmount (state) {
    return state.transactionsAmount
  },
  dateFrom (state) {
    return state.dateFrom
  },
  dateTo (state) {
    return state.dateTo
  },
  funeralCompany (state) {
    return state.funeralCompany
  },
  paidFilter (state) {
    return state.paidFilter
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
