import MainLayout from '@/layouts/LayoutBlank'

export default [{
  path: '/',
  component: MainLayout,
  children: [
    {
      path: '/',
      name: 'login',
      component: () => import('@/modules/login/index')
    },
    {
      path: '/users/password-remind/:token',
      name: 'remind-token',
      props: true,
      component: () => import('@/modules/login/remind-password')
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/modules/login/index'),
      beforeRouteEnter (to, from, next) {
        console.log('twesasfg')
        this.$store.dispatch('users/logout')
      }
    }
  ]
}]
