import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/reports/datatables', {
      params: query
    })
  },
  remove (id) {
    return HTTP.delete(`panel/reports/${id}`)
  },
  archive (id, flag = true) {
    return HTTP.put(`panel/reports/archive/${id}`, {
      archive: flag ? 1 : 0
    })
  }
}
