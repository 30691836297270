import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/funerals/datatables', {
      params: query
    })
  },
  saveRaw (data) {
    return HTTP.post(`panel/funerals/save-raw`, data)
  },
  changeOwner (funeralId, targetUserId) {
    return HTTP.post(`panel/funerals/change-owner`, {
      funeralId, targetUserId
    })
  },
  remove (id) {
    return HTTP.delete(`panel/funerals/${id}`)
  },
  archive (id, flag = true) {
    return HTTP.put(`panel/funerals/archive/${id}`, {
      archive: flag ? 1 : 0
    })
  },
  saveValidTo (id, validTo = null) {
    return HTTP.put(`panel/funerals/valid-to/${id}`, {
      valid_to: validTo
    })
  },
  funeralDetails (id) {
    return HTTP.get(`panel/funerals/${id}`)
  }
}
