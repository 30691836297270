import jquery from 'jquery'

const deepCopy = (object) => {
  return jquery.extend(true, {}, object)
}
const objectToArray = (object) => {
  return Object.keys(object).map(function (key) { return object[key] })
}

export { deepCopy, objectToArray }
