import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/tombstone-additions/datatables', {
      params: query
    })
  },
  save (data) {
    if (data.id) {
      return HTTP.put('panel/tombstone-additions/' + data.id, data)
    }
    return HTTP.post(`panel/tombstone-additions`, data)
  },
  all () {
    return HTTP.get(`panel/tombstone-additions`)
  },
  remove (id) {
    return HTTP.delete(`panel/tombstone-additions/${id}`)
  }
}
