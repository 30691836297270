import HTTP from './HTTP'

export default {
  login (credentials) {
    return HTTP.post('users/login', credentials)
  },
  getByToken (token) {
    return HTTP.get(`users/by-token/${token}`)
  },
  changePassword (data) {
    return HTTP.post('users/password/change', data)
  },
  userDetails (id) {
    return HTTP.get(`users/details/${id}`)
  },
  userAffiliations (id, queryData) {
    return HTTP.get(`users/details/${id}/affiliations`, {
      params: queryData
    })
  },
  userAffiliation (id) {
    return HTTP.get(`users/affiliation/${id}`)
  },
  me () {
    return HTTP.get('users/me')
  },
  logout () {
    return HTTP.get('users/logout')
  },
  save (user) {
    if (user.id) {
      return HTTP.put('users/' + user.id, user)
    }
    return HTTP.post('users', user)
  },
  remove (id) {
    return HTTP.delete('users/' + id)
  },
  sendNewPassword (id) {
    return HTTP.post(`users/send-new-password/${id}`)
  },
  sendConfirmationEmail (id) {
    return HTTP.post(`users/send-confirmation-email/${id}`)
  },
  remindPassword (email) {
    return HTTP.post('users/password/reminder', { user: { email }, env: 'panel' })
  },
  suspend (id, flag) {
    return HTTP.post(`users/suspend/${id}`, { flag })
  },
  datatables (query, type = 'admin') {
    return HTTP.get('users/datatables/' + type, {
      params: query
    })
  },
  search (query) {
    return HTTP.get('users/search', {
      params: query
    })
  },
  funeralCompanies (includeAdmins = false) {
    let query = {}
    if (includeAdmins) {
      query.include_admins = true
    }
    return HTTP.get('users/funeral-companies', {
      params: query
    })
  }
}
