const language = {
  global: {
    app: 'Virtual Graveyard',
    'not-implemented': 'Not implemented yet...',
    saved: 'Data saved',
    removed: 'Record removed',
    archived: 'Record archived',
    activated: 'Record activated',
    amount: 'Amount',
    ok: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    clear: 'Clear',
    close: 'Close',
    generate: 'Generate',
    remove: 'Remove',
    addImage: 'Add image',
    comment: 'Comment',
    saveAndBack: 'Save and back',
    chooseColumns: 'Choose columns',
    indefinitely: 'Indefinetly',
    atLeastThree: 'Please type at least 3 characters',
    search: 'Search',
    buriedPeople: 'Buried people',
    ownerAll: 'Please keep in mind that you are changing owner of the all people buried in this grave.',
    required: 'Required.'
  },
  languages: [
    {
      value: 'pl',
      text: 'Polski'
    }, {
      value: 'en',
      text: 'English'
    }, {
      value: 'sk',
      text: 'Slovenský'
    }
  ],
  forms: {
    login: {
      emailPhone: 'Email / Phone number',
      forgotPassword: 'Forgot password?',
      password: 'Password',
      rememberMe: 'Remember me',
      login: 'Login',
      loginScreen: 'Login',
      remindPassword: 'Remind Password',
      metaTitle: 'Login'
    }
  },
  menu: {
    dashboard: 'Dashboard',
    transactions: 'Transactions',
    deadBook: 'Dead book',
    animalBook: 'Animal book',
    tombstones: 'Tombstones',
    tombstoneAdditions: 'Tombstone additions',
    users: 'Users',
    admins: 'Admins',
    partners: 'Partners',
    ads: 'Ads',
    pricelist: 'Pricelist',
    pricelistFuneralHouses: 'Partners pricelist',
    reports: 'Reports',
    sites: 'Sites',
    logout: 'Logout'
  },
  paidOptions: [
    {
      value: null,
      text: 'All'
    },
    {
      value: 'paid',
      text: 'Paid only'
    },
    {
      value: 'free',
      text: 'Free only'
    }
  ],
  contents: {
    dashboard: {
      title: 'Statistics',
      mainStatistics: 'Tombstones on cemetery',
      catacombsStatistics: 'Tombsones in catacombs',
      animalStatistics: 'Tombstones in animal cemetery',
      registeredUsers: 'Registered users',
      religion: 'Religion',
      mainCemetery: 'Cemetery',
      catacombs: 'Catacombs',
      last24h: 'Last 24h',
      last7days: 'Last 7 days',
      lastMonth: 'Last month',
      lastYear: 'Last year',
      registeredInTime: 'Registered in time',
      loggedInTime: 'Logged in time',
      funeralsPaid: 'Funerals paid',
      funeralsFree: 'Free funerals',
      additionsPaid: 'Additions paid',
      additionsFree: 'Free additions'
    },
    transactions: {
      title: 'Transactions',
      transactionId: 'Transaction ID',
      paidUntil: 'Paid until',
      user: 'User',
      orderType: 'Order type',
      gateway: 'Payment gateway',
      price: 'Price',
      created: 'Created',
      export_invoices: 'Export invoices',
      total: 'Total price',
      paid_filter: 'Transaction type',
      room: 'Funeral',
      finished: 'finished',
      unpaid: 'unpaid',
      removeModal: {
        title: 'Remove transaction',
        elements: 'Which elements you want ro remove?',
        all: 'Transaction, grave and people',
        transaction: 'Only transaction'
      },
      invoices: {
        date_from: 'Date from',
        date_to: 'Date to',
        funeral_company: 'Partner',
        pick_one: 'Choose to filter data',
        get_invoices_link: 'Get invoices in PDF',
        get_edipp_link: 'Export invoices to EdiPP',
        get_edipp_link_disabled: 'You need to choose "date from" and "date to" to export invoices to EdiPP format',
        amount_of_invoices: 'Amount of invoices in selected period',
        file: 'invoices',
        single: 'invoice',
        none: 'none'
      },
      actions: {
        title: 'Actions',
        invoice: 'Invoice',
        edit: 'Edit',
        remove: 'Remove'
      },
      export_transactions: 'Export transactions',
      export: {
        with_invoices: 'With Invoices',
        without_invoices: 'Without Invoices',
        refunds: 'Refunds',
        get_transactions_link: 'Get transactions',
        file: 'export'
      }
    },
    deadBook: {
      title: 'Dead book',
      add: 'Add tombstone',
      created: 'Created at',
      filter: 'Filter',
      name: 'Name',
      surname: 'Surname',
      nick: 'Nick',
      sex: 'Sex',
      birthday: 'Birthday',
      death: 'Death',
      place: 'Place',
      link: 'Link',
      actions: {
        title: 'Actions',
        edit: 'Edit',
        move: 'Change owner',
        remove: 'Remove'
      },
      showModal: {
        close: 'Close',
        edit: 'Edit tombstone',
        add: 'Add tombstone',
        name: 'Name',
        surname: 'Surname',
        nick: 'Nick',
        sex: 'Sex',
        birthday: 'Date of birth',
        death: 'Date of death',
        memorial: 'Memorial', // podpis
        memorial_text: 'Memorial text',
        ownerName: 'Owner name',
        ownerSurname: 'Owner surname',
        ownerEmail: 'Owner email',
        ownerPhone: 'Owner phone',
        emailContent: 'Email content',
        smsContent: 'SMS content',
        variables: 'Variables',
        varName: 'deceased name',
        varSurname: 'deceased surname',
        varLink: 'direct link to the grave',
        portrait: 'Portrait',
        album: 'Album',
        autoSend: 'Autosending when filled'
      },
      moveModal: {
        title: 'Change owner',
        sourceUser: 'Source user',
        targetUser: 'Target user'
      }
    },
    animalBook: {
      title: 'Animal book'
    },
    tombstones: {
      title: 'Tombstones',
      allCemeteries: 'All cemeteries',
      allReligions: 'Municipal cemetery',
      allTombstoneTypes: 'All tombstone types',
      ordering: 'Tombstones ordering',
      add: 'Add tombstone',
      id: 'ID',
      image: 'Image',
      place: 'Place',
      religion: 'Religion',
      species: 'Species',
      type: 'Type',
      used: 'Used times',
      actions: {
        title: 'Actions',
        edit: 'Edit',
        remove: 'Remove'
      },
      newTombstone: 'New tombstone',
      editTombstone: 'Edit tombstone',
      chooseAllFilters: 'Choose all filters to show the order on the site. Moving the picture automatically saves changes.'
    },
    tombstoneAdditions: {
      title: 'Tombstone additions',
      image: 'Image',
      type: 'Type',
      all: 'All types',
      add: 'Add addition',
      allTypes: 'Add types',
      used: 'Used times',
      actions: {
        title: 'Actions',
        edit: 'Edit',
        remove: 'Remove'
      },
      newTombstoneAddition: 'New addition',
      editTombstoneAddition: 'Edit addition'
    },
    users: {
      representative: 'Represetative',
      address: 'Address',
      url: 'WWW',
      tax_id: 'TAX',
      title: 'Users',
      user: 'User',
      addUser: 'Add a user',
      id: 'ID',
      surname: 'Surname',
      surnameName: 'Surname / Company name',
      companyName: 'Company name',
      discount: 'Discount - Funerals (0-100%)',
      souvenirs_discount: 'Discount - Souvenirs (0-100%)',
      nick: 'Nickname',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      emailConfirmed: 'Email confirmed at',
      activeTombstones: 'Active tombstones',
      activeTombstonesShort: 'AC.T.',
      archiveTombstones: 'Archive tombstones',
      archiveTombstonesShort: 'AR.T.',
      additions: 'Additions',
      registration: 'Registration',
      lastLogin: 'Last login',
      emailVerified: 'Account verified',
      emailVerifiedShort: 'AV',
      agreedNotifications: 'Agreed for notifications',
      agreedNotificationsShort: 'AN',
      agreedEmails: 'Agreed for emails',
      agreedEmailsShort: 'AE',
      role: 'Role',
      newPassword: 'New password',
      newPasswordAgain: 'Repeat new password',
      newPasswordWillBeSent: 'New password will be sent to email.',
      lastLogged: 'Last logged',
      tombstoneValid: 'Tombstone valid to date',
      validTo: 'Valid to',
      menuAccess: 'Menu access',
      confirmDeleteTitle: 'Delete confirmation',
      confirmDelete: 'Are you sure you want to delete this user? This user has: <strong>{0}</strong> active tombstones, <strong>{1}</strong> archive tombstones and <strong>{2}</strong> additions.',
      monthlyInvoices: 'Monthly invoices',
      editUser: 'Edit a user',
      randomPassword: 'If you don\'t put users password the randome one will be sent to provided email.',
      actions: {
        title: 'Actions',
        edit: 'Edit',
        impersonate: 'Impersonate',
        remove: 'Remove',
        suspend: 'Suspend account',
        active: 'Active account',
        newPassword: 'Send new password',
        confirmRegistration: 'Send email confirmation'
      },
      details: {
        tombstones: {
          name: 'Tombstones',
          noSingleTombstones: 'No single tombstones',
          noMultiTombstones: 'No multi tombstones',
          noAnimalTombstones: 'No animal tombstones',
          nick: 'Nick',
          nameSurname: 'Name and surname',
          death: 'Death date',
          tombstoneId: 'Tombstone ID',
          addedDate: 'Added date (IP)',
          validUntil: 'Paid until',
          link: 'Link',
          inArchives: 'In archives',
          linkName: 'Cemetery Link',
          extend: 'Extend',
          activate: 'Activate',
          archive: 'Archive',
          remove: 'Remove'
        },
        transactions: {
          name: 'Transactions',
          date: 'Date (IP)',
          type: 'Type',
          price: 'Price',
          concerns: 'Concerns',
          noTransactions: 'No transactions'
        },
        affiliation: {
          name: 'Affiliation',
          urlPl: 'Affiliation URL [PL]',
          urlEn: 'Affiliation URL [EN]',
          generateAffiliation: 'Generate affiliation link for this user',
          month: 'Month',
          monthlyIncome: 'Total income for selected month'
        },
        invoices: {
          name: 'Invoices',
          id: 'Invoice number',
          date: 'Date',
          type: 'Type',
          price: 'Price',
          get_invoice: 'Get invoice',
          noInvoices: 'No invoices'
        }
      }
    },
    admins: {
      name: 'Admins'
    },
    partners: {
      name: 'Partners',
      notificationHint: 'Notifications about new partners will be sent to the e-mail address provided. Leave blank to turn off.'
    },
    ads: {
      title: 'Ads',
      addAd: 'Add an ad',
      name: 'Name',
      link: 'Link',
      zone: 'Zone',
      showingFrom: 'Showing from',
      showingTo: 'Showing to',
      time: 'Rotation',
      actions: {
        title: 'Actions',
        edit: 'Edit',
        remove: 'Remove'
      },
      newAd: 'New ad',
      editAd: 'Edit an ad',
      rotation: 'Rotation time [s]',
      image: 'Image'
    },
    pricelist: {
      activeShort: 'A',
      active: 'Is active?',
      title: 'Title',
      validDays: 'Valid days (0 - forever)',
      addPosition: 'Add position',
      remove: 'Remove'
    },
    reports: {
      created: 'Report date',
      report: 'Report',
      condolence: 'Condolence',
      funeral: 'Funeral',
      lodging_no: 'Acc. no.',
      actions: {
        archive: 'Archive',
        remove: 'Delete'
      }
    },
    sites: {
      title: 'Sites',
      name: 'Title'
    }
  },
  users: {
    'new-password-sent': 'New password has been sent.',
    'confirmation-email-sent': 'Confirmation email has been sent.',
    'account-unsuspended': 'Account unsuspended',
    'account-suspended': 'Account suspended',
    'account-suspended-title': 'Account suspended',
    'account-suspended-l': '(S)',
    'password-changed': 'Password changed. You can log in now.'
  },
  places: {
    main: 'Cemetery',
    catacombs: 'Catacombs',
    animal: 'Animal C.'
  },
  transaction: {
    type: {
      tombstone: 'Tombstone',
      addition: 'Addition'
    }
  },
  genders: {
    m: 'Male',
    f: 'Female',
    'null': '-'
  },
  gendersAnimals: {
    m: 'M',
    f: 'F',
    'null': '-'
  },
  roles: {
    'super-admin': 'Super Admin',
    'admin': 'Admin',
    'funeral-company': 'Partner',
    'user': 'User'
  },
  permissions: {
    'transactions': 'Transactions',
    'dead-book': 'Dead book',
    'animal-book': 'Animal book',
    'tombstones': 'Tombstones',
    'tombstone-additions': 'Tombstones additions',
    'users': 'Users',
    'admins': 'Admins',
    'partners': 'Partners',
    'ads': 'Ads',
    'pages': 'Pages',
    'prices': 'Pricelist',
    'prices/funeral-house-prices': 'Partners pricelist'
  },
  zones: {
    zone1: 'Information board 2nd position',
    zone2: 'Information board 4th position',
    zone3: 'Ghost',
    zone4: 'Zone 4',
    zone5: 'Zone 5',
    zone6: 'Zone 6'
  },
  tombstoneAdditions: {
    light: 'Light',
    flower: 'Flower',
    stone: 'Stone',
    other: 'Other'
  },
  periods: {
    '1-day': '1 day',
    '30-days': '30 days',
    '1-year': '1 year',
    'forever': 'forever'
  },
  religions: {
    catholicism: {
      name: 'Catholicism'
    },
    buddhism: {
      name: 'Buddhism'
    },
    bible_students_jehov: {
      name: 'Bible Students Jehov'
    },
    orthodoxy: {
      name: 'Orthodoxy'
    },
    hinduism: {
      name: 'Hinduism'
    },
    islam: {
      name: 'Islam'
    },
    judaism: {
      name: 'Judaism'
    },
    shinto: {
      name: 'Shinto'
    },
    atheism: {
      name: 'Atheism'
    },
    romas: {
      name: 'Romas'
    },
    polish_tatars: {
      name: 'Polish Tatars'
    },
    others: {
      name: 'Others'
    },
    celebrities: {
      name: 'Celebrities'
    },
    null: {
      name: 'Municipal Cemetery'
    },
    none: 'Municipal Cemetery'
  },
  species: {
    reptiles: {
      name: 'Reptiles'
    },
    rodents: {
      name: 'Rodents'
    },
    horses: {
      name: 'Horses'
    },
    cats: {
      name: 'Cats'
    },
    monkeys: {
      name: 'Monkeys'
    },
    insects: {
      name: 'Insects'
    },
    amphibians: {
      name: 'Amphibians'
    },
    dogs: {
      name: 'Dogs'
    },
    birds: {
      name: 'Birds'
    },
    fishes: {
      name: 'Fishes'
    },
    mammals_others: {
      name: 'Mammals - other'
    },
    others: {
      name: 'Other'
    },
    none: 'None'
  },
  typeAmount: {
    single: {
      name: 'Single'
    },
    double: {
      name: 'Double'
    },
    multi: {
      name: 'Multi'
    }
  },
  login: {
    'incorrect-email-password': 'Incorrect email or password',
    success: 'Logged in successfully'
  },
  logout: {
    success: 'Logged out successfully'
  },
  prices: {
    title: 'Pricelist'
  },
  graves: {
    single: 'Single person grave',
    multi: 'Multi person grave',
    additions: 'Additions'
  },
  transactions: {
    'App\\Models\\Funerals\\Funeral': {
      'info': {
        title: 'Place'
      },
      'model-created': 'New place',
      'model-extended': 'Place extension'
    },
    'App\\Models\\Funerals\\Person': {
      'info': {
        title: 'Tombstone'
      },
      'model-created': 'New tombstone',
      'model-extended': 'Tombstone extension'
    },
    'App\\Models\\Memory': {
      'info': {
        title: 'Addition'
      },
      'model-created': 'New addition',
      'model-extended': 'Addition extension'
    }
  },
  validator: {
    title: 'Validation error'
  },
  validation: {
    email: 'Incorrect email',
    confirm: 'Fields must match',
    unique: 'Field needs to be unique'
  }
}

export default language
