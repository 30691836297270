import HTTP from './HTTP'

export default {
  datatables (query) {
    return HTTP.get('panel/transactions/datatables', {
      params: query
    })
  },
  remove (id) {
    return HTTP.delete(`panel/transactions/${id}`)
  },
  removeAll (id) {
    return HTTP.delete(`panel/transactions/${id}/all`)
  },
  invoicesAmount (dateFrom, dateTo) {
    return HTTP.get('panel/transactions/invoices-amount', {
      params: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  },
  getInvoices (dateFrom, dateTo) {
    return HTTP.blob().get('panel/transactions/get-invoices', {
      params: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  },
  getEdiPP (dateFrom, dateTo) {
    return HTTP.blob().get('panel/transactions/get-edipp', {
      params: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  },
  transactionsAmount (dateFrom, dateTo) {
    return HTTP.get('panel/transactions/transactions-amount', {
      params: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  },
  getTransactions (dateFrom, dateTo) {
    return HTTP.blob().get('panel/transactions/get-transactions', {
      params: {
        date_from: dateFrom,
        date_to: dateTo
      }
    })
  },
  invoice (id) {
    return HTTP.blob().get(`panel/transactions/${id}/invoice`)
  }
}
