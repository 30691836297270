// import api from "@/configs/api";

import PricesService from '@/services/PricesService'
import CurrenciesService from '@/services/CurrenciesService'

const state = {
  all: {},
  currencies: []
}

const actions = {
  all ({ commit }, data) {
    return PricesService.all(data.pricelistType).then(response => {
      if (response.data.success) {
        response.data.data.forEach(e => {
          e.currenciesRaw = {}
          e.prices.forEach(e2 => {
            e.currenciesRaw[e2.currency_id] = e2.price
          })
        })
        commit('all', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  currencies ({ commit }) {
    return CurrenciesService.all().then(response => {
      if (response.data.success) {
        commit('currencies', response.data.data)
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  save ({ commit }, data) {
    return PricesService.save(state.all, data.pricelistType).then(response => {
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  all (state, data) {
    state.all = data
  },
  currencies (state, data) {
    state.currencies = data
  }
}

const getters = {
  all (state) {
    return state.all
  },
  currencies (state) {
    return state.currencies
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
