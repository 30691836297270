import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import errors from './modules/errors'
import indicators from './modules/indicators'
import deadBook from './modules/dead-book'
import tombstones from './modules/tombstones'
import tombstoneAdditions from './modules/tombstoneAdditions'
import transactions from './modules/transactions'
import rowGallery from './modules/rowGallery'
import religions from './modules/religions'
import ads from './modules/ads'
import statistics from './modules/statistics'
import prices from './modules/prices'
import sites from './modules/sites'
import species from './modules/species'
import reports from './modules/reports'
import options from './modules/options'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    options, deadBook, users, errors, indicators, tombstones, tombstoneAdditions, transactions, rowGallery, religions, ads, statistics, prices, sites, species, reports
  }
})
