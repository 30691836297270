// import api from "@/configs/api";

import FuneralsService from '@/services/FuneralsService'

const state = {
  datatablesConfiguration: {
    rowClick: (datatables, data, clickEvent) => {
      window.app.$router.push({ name: 'user-details', params: { id: data.user_id } })
    },
    ajax: (data, callback, settings) => {
      data.selected_cemetery = state.selectedCemetery
      actions.inDatatables(data).then((result) => {
        state.inDatatables = result.data.data
        callback(result.data.data)
      })
    },
    order: [[0, 'desc']],
    processing: true,
    serverSide: true,
    dom: 'Bfrtip',
    buttons: [
      'colvis'
    ],
    columnDefs: [
      {
        targets: 0,
        orderable: true
      }, {
        targets: 1,
        orderable: true
      }, {
        targets: 2,
        orderable: true
      }, {
        targets: 3,
        orderable: true
      }, {
        targets: 4,
        orderable: true
      }, {
        targets: 5,
        orderable: true
      }, {
        targets: 6,
        orderable: true
      }, {
        targets: 7,
        orderable: true
      }, {
        targets: 8,
        orderable: false
      }, {
        targets: 9,
        orderable: false
      }
    ],
    searchDelay: 500
  },
  selectedCemetery: ['main', 'catacombs'],
  current: {
    place: 'main',
    people: [{}]
  },
  funeralDetails: null
}

const actions = {
  inDatatables (query) {
    return FuneralsService.datatables(query).then(result => {
      return result
    }).catch((e) => {
      return false
    })
  },
  remove ({ commit }, person) {
    return FuneralsService.remove(person.id).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  archive ({ commit }, person) {
    return FuneralsService.archive(person.id, !person.archived).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  saveValidTo ({ commit }, person) {
    return FuneralsService.saveValidTo(person.id, person.valid_until).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  saveRaw ({ commit }, data) {
    return FuneralsService.saveRaw(data).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  changeOwner ({ commit }, data) {
    return FuneralsService.changeOwner(data.funeralId, data.targetUserId).then(response => {
      if (response.data.success) {
        return true
      }
      return false
    }).catch((e) => {
      return false
    })
  },
  funeralDetails ({ commit }, funeralId) {
    return FuneralsService.funeralDetails(funeralId).then(response => {
      if (response.data.success) {
        commit('funeralDetails', response.data.data)
        return response.data.data
      }
      return false
    }).catch((e) => {
      return false
    })
  }
}

const mutations = {
  inDatatables (state, data) {
    state.inDatatables = data
  },
  current (state, data) {
    state.current = data
  },
  selectedCemetery (state, data) {
    state.selectedCemetery = data
  },
  funeralDetails (state, data) {
    state.funeralDetails = data
  }
}

const getters = {
  datatablesConfiguration (state) {
    return state.datatablesConfiguration
  },
  inDatatables (state) {
    return state.inDatatables
  },
  current (state) {
    return state.current
  },
  selectedCemetery (state) {
    return state.selectedCemetery
  },
  funeralDetails (state) {
    return state.funeralDetails
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
