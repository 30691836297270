import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'

import globals from '@/globals'

import loginRoutes from './login'
import dashboardRoutes from './dashboard'
import usersRoutes from './users'
import deadBookRoutes from './deadBook'
import animalBookRoutes from './animalBook'
import transactionsRoutes from './transactions'
import tombstonesRoutes from './tombstones'
import tombstoneAdditionsRoutes from './tombstoneAdditions'
import adminsRoutes from './admins'
import adsRoutes from './ads'
import pricesRoutes from './prices'
import sitesRoutes from './sites'
import partnersRoutes from './partners'
import reportsRoutes from './reports'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  // { path: '', redirect: '/' }
]
  .concat(loginRoutes)
  .concat(dashboardRoutes)
  .concat(usersRoutes)
  .concat(deadBookRoutes)
  .concat(transactionsRoutes)
  .concat(tombstonesRoutes)
  .concat(tombstoneAdditionsRoutes)
  .concat(adminsRoutes)
  .concat(animalBookRoutes)
  .concat(adsRoutes)
  .concat(pricesRoutes)
  .concat(sitesRoutes)
  .concat(reportsRoutes)
  .concat(partnersRoutes)

// 404 Not Found page
ROUTES.push({
  path: '*',
  component: NotFound
})

const router = new Router({
  base: '/',
  mode: 'history',
  routes: ROUTES
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
