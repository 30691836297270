import MainLayout from '@/layouts/Layout2'

export default [{
  path: '/tombstones',
  component: MainLayout,
  children: [{
    path: '/',
    component: () => import('@/modules/tombstones/index')
  }]
}]
